import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PortfoliosState, Portfolio } from "../types";

const initialState: PortfoliosState = {
    portfolios: [],
    activePortfolio: null,
};

export const portfoliosReducer = createSlice({
    name: 'portfolios',
    initialState,
    reducers: {
        setPortfolios: (state, action: PayloadAction<Portfolio[]>) => {
            state.portfolios = action.payload;
        },
        setActivePortfolio: (state, action: PayloadAction<Portfolio | null>) => {
            state.activePortfolio = action.payload;
        }
    },
});

export const { setPortfolios, setActivePortfolio } = portfoliosReducer.actions;

export default portfoliosReducer.reducer; 