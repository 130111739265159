import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StocksState, StockValue } from "../types";

const initialState: StocksState = {
    currentValues: null,
};

export const stocksReducer = createSlice({
    name: 'stocks',
    initialState,
    reducers: {
        setCurrentValues: (state, action: PayloadAction<StockValue[] | null>) => {
            state.currentValues = action.payload;
        },
    },
});

export const { setCurrentValues } = stocksReducer.actions;

export default stocksReducer.reducer; 