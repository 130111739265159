import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatusState } from "../types";

interface LoginInfo {
    token: string;
    userID: number;
}

const initialState: StatusState = {
    showLoginDialog: false,
    token: '',
    userID: -1,
};

export const statusReducer = createSlice({
    name: 'status',
    initialState,
    reducers: {
        setShowLoginDialog: (state, action: PayloadAction<boolean>) => {
            state.showLoginDialog = action.payload;
        },
        setLoginInfo: (state, action: PayloadAction<LoginInfo>) => {
            state.token = action.payload.token;
            state.userID = action.payload.userID;
        }
    }
});

export const { setShowLoginDialog, setLoginInfo } = statusReducer.actions;

export default statusReducer.reducer; 