import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WatchedStocksState, WatchedStock } from "../types";

const initialState: WatchedStocksState = {
    watchedStocksList: [],
    currentWatchedStock: null,
};

export const watchedStocksReducer = createSlice({
    name: 'watchedStocks',
    initialState,
    reducers: {
        setWatchedStocksList: (state, action: PayloadAction<WatchedStock[]>) => {
            state.watchedStocksList = action.payload;
        },
        setCurrentWatchedStock: (state, action: PayloadAction<WatchedStock | null>) => {
            state.currentWatchedStock = action.payload;
        }
    }
});

export const { setWatchedStocksList, setCurrentWatchedStock } = watchedStocksReducer.actions;

export default watchedStocksReducer.reducer; 