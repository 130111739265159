import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompaniesState, Company } from "../types";

const initialState: CompaniesState = {
    companiesList: [],
    ipoList: [],
    currentCompany: null,
};

export const companiesReducer = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        setCompaniesList: (state, action: PayloadAction<Company[]>) => {
            state.companiesList = action.payload;
        },
        setIPOList: (state, action: PayloadAction<Company[]>) => {
            state.ipoList = action.payload;
        },
        setCurrentCompany: (state, action: PayloadAction<Company | null>) => {
            state.currentCompany = action.payload;
        }
    }
});

export const { setCompaniesList, setIPOList, setCurrentCompany } = companiesReducer.actions;

export default companiesReducer.reducer; 