import React, { useState, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { setShowLoginDialog, setLoginInfo } from '../slices/statusSlice';
import API from '../lib/API';

const LoginModal: React.FC = () => {
    const dispatch = useDispatch();
    const show = useSelector((state: RootState) => state.status.showLoginDialog);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleClose = (): void => {
        setUsername('');
        setPassword('');
        dispatch(setShowLoginDialog(false));
    }

    const login = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        API.login(username, password).then(response => {
            dispatch(setLoginInfo(response));
            handleClose();
        }).catch(error => {
            setPassword('');
            setErrorMessage(error ? error.toString() : 'Unable to log you in, try again later');
        });
    }

    return (
        <Modal show={show} onHide={handleClose} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Log in</Modal.Title>
            </Modal.Header>
            <Modal.Body className='show-grid'>
                <Container>
                    {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}
                    <Form onSubmit={login}>
                        <Form.Group className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Username" 
                                value={username} 
                                onChange={(e) => setUsername(e.target.value)} 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control 
                                type="password" 
                                placeholder="Password" 
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                            />
                        </Form.Group>
                        <Button type="submit" variant="success">
                            Login
                        </Button>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal; 