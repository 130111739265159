import React, { useState, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import API from '../lib/API';
import { TransactionFormModalProps, TransactionType } from '../types';

const TransactionFormModal: React.FC<TransactionFormModalProps> = ({ show, transaction, handleClose, portfolios }) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [ticker, setTicker] = useState<string>(transaction ? transaction.ticker : '');
    const [price, setPrice] = useState<string>(transaction ? transaction.price.toString() : '');
    const [quantity, setQuantity] = useState<string>(transaction ? transaction.quantity.toString() : '');
    const [date, setDate] = useState<string>(transaction ? transaction.date : '');
    const [type, setType] = useState<TransactionType>(transaction ? transaction.type : 'BUY');
    const [portfolio, setPortfolio] = useState<string>(transaction ? (transaction.portfolio ? transaction.portfolio.toString() : '') : '');
    const [note, setNote] = useState<string>(transaction ? (transaction.note ? transaction.note : '') : '');
    const token = useSelector((state: RootState) => state.status.token);
    const userID = useSelector((state: RootState) => state.status.userID);

    const submit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (isNaN(Number(price)) || isNaN(Number(quantity))) {
            setErrorMessage('Price and quantity have to be numbers');
            return;
        }

        if (!(ticker && date && type)) {
            setErrorMessage('Ticker, date and type are required');
            return;
        }

        if (transaction) {
            API.updateStockTransaction(token, transaction.id, userID ?? 0, ticker, Number(price).toFixed(2), Number(quantity), date, Number(portfolio), note).then(response => {
                handleClose();
            }).catch(error => {
                setErrorMessage(error ? error.toString() : 'Error updating stock transaction');
            });
        } else {
            API.createStockTransaction(token, userID ?? 0, ticker, Number(price).toFixed(2), Number(quantity), date, type, Number(portfolio), note).then(response => {
                handleClose();
            }).catch(error => {
                setErrorMessage(error ? error.toString() : 'Error creating stock transaction');
            });
        }
    };

    const deleteStockTransaction = (): void => {
        if (!transaction) {
            return;
        }

        API.deleteStockTransaction(token, transaction.id).then(response => {
            handleClose();
        }).catch(error => {
            setErrorMessage(error ? error.toString() : 'Error deleting stock transaction');
        });
    }
    
    return (
        <Modal show={show} onHide={handleClose} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{transaction ? 'Edit transaction' : 'New transaction'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='show-grid'>
                <Container>
                    {errorMessage ? <Alert variant='danger'>{errorMessage}</Alert> : null}
                    <Form onSubmit={submit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Ticker</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Ticker" 
                                value={ticker} 
                                onChange={(e) => setTicker(e.target.value)} 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Type</Form.Label>
                            <Form.Select 
                                value={type} 
                                onChange={(e) => setType(e.target.value as TransactionType)}
                            >
                                <option value="BUY">BUY</option>
                                <option value="SELL">SELL</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Price per share</Form.Label>
                            <Form.Control 
                                type="number" 
                                placeholder="Price" 
                                value={price} 
                                onChange={(e) => setPrice(e.target.value)} 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control 
                                type="number" 
                                placeholder="Quantity" 
                                value={quantity} 
                                onChange={(e) => setQuantity(e.target.value)} 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Date</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="YYYY-MM-DD" 
                                value={date} 
                                onChange={(e) => setDate(e.target.value)} 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Portfolio</Form.Label>
                            <Form.Select 
                                value={portfolio} 
                                onChange={(e) => setPortfolio(e.target.value)}
                            >
                                <option value="">None</option>
                                {portfolios.map(p => (
                                    <option key={p.id} value={p.id}>{p.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Note</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Note" 
                                value={note} 
                                onChange={(e) => setNote(e.target.value)} 
                            />
                        </Form.Group>
                        <>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                            {transaction ? (
                                <Button variant="danger" onClick={deleteStockTransaction}>Delete</Button>
                            ) : null}
                        </>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default TransactionFormModal; 