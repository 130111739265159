import React, { useState, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import API from '../lib/API';
import { WatchedStockFormModalProps } from '../types';

const WatchedStockFormModal: React.FC<WatchedStockFormModalProps> = ({ show, stock, handleClose }) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [ticker, setTicker] = useState<string>(stock ? stock.ticker : '');
    const [price, setPrice] = useState<string>(stock ? stock.price : '');
    const [comparison, setComparison] = useState<'less' | 'greater'>(stock ? stock.comparisonInfo.comparison : 'less');
    const [emailList, setEmailList] = useState<string>(stock ? stock.emailList : '');
    const token = useSelector((state: RootState) => state.status.token);

    const submit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (isNaN(Number(price))) {
            setErrorMessage('Price must be a number');
            return;
        }

        if (stock) {
            API.updateWatchedStock(token, stock.id, stock.ticker, Number(price).toFixed(2), emailList, { comparison }).then(_ => {
                handleClose();
            }).catch(error => {
                setErrorMessage(error ? error.toString() : 'Error updating watched stock');
            });
        } else {
            API.createWatchedStock(token, ticker, Number(price).toFixed(2), emailList, { comparison }).then(_ => {
                handleClose();
            }).catch(error => {
                setErrorMessage(error ? error.toString() : 'Error creating watched stock');
            });   
        }
    }

    const deleteWatchedStock = (): void => {
        if (!stock) return;
        
        API.deleteWatchedStock(token, stock.id).then(_ => {
            handleClose();
        }).catch(error => {
            setErrorMessage(error ? error.toString() : 'Error deleting watched stock');
        });
    }

    return (
        <Modal show={show} onHide={handleClose} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{stock ? stock.ticker : 'New Watched Stock'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='show-grid'>
                <Container>
                    {errorMessage ? <Alert variant='danger'>{errorMessage}</Alert> : null}
                    <Form onSubmit={submit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Ticker</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Ticker" 
                                value={ticker} 
                                onChange={e => setTicker(e.target.value)} 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Comparison</Form.Label>
                            <Form.Select 
                                value={comparison} 
                                onChange={e => setComparison(e.target.value as 'less' | 'greater')}
                            >
                                <option value="less">Less than</option>
                                <option value="greater">Greater than</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Price</Form.Label>
                            <Form.Control 
                                type="number" 
                                placeholder="Price" 
                                value={price} 
                                onChange={e => setPrice(e.target.value)} 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email list</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="email list" 
                                value={emailList} 
                                onChange={e => setEmailList(e.target.value)} 
                            />
                        </Form.Group>
                        <div className="d-flex gap-2">
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                            {stock ? (
                                <Button variant="danger" onClick={deleteWatchedStock}>Delete</Button>
                            ) : null}
                        </div>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default WatchedStockFormModal; 