import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

import { setShowLoginDialog, setLoginInfo } from '../slices/statusSlice';

const TAB_HOME = 'Home';
const TAB_WATCHED_STOCKS = 'Watched Stocks';
const TAB_PORTFOLIO = 'Portfolio';
const TAB_SPEND = 'Spend';

interface MainNavBarProps {
    tab: string;
}

const MainNavBar: React.FC<MainNavBarProps> = ({ tab }) => {
    const isActiveTab = (tabName: string): boolean => {
        return tab === tabName;
    }

    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.status.token);

    const logout = (): void => {
        dispatch(setLoginInfo({ token: '', userID: -1 }));
        window.location.href = '/';
    }

    return (
        <>
            <Navbar bg="primary" variant="dark">
                <Container>
                    <Navbar.Brand>Caramel</Navbar.Brand>
                    <Nav className='me-auto'>
                        <Nav.Link as={Link} to='/' active={isActiveTab(TAB_HOME)}>{TAB_HOME}</Nav.Link>
                        <Nav.Link as={Link} to='/watch' active={isActiveTab(TAB_WATCHED_STOCKS)}>{TAB_WATCHED_STOCKS}</Nav.Link>
                        <Nav.Link as={Link} to='/portfolio' active={isActiveTab(TAB_PORTFOLIO)}>{TAB_PORTFOLIO}</Nav.Link>
                        <Nav.Link as={Link} to='/spend' active={isActiveTab(TAB_SPEND)}>{TAB_SPEND}</Nav.Link>
                    </Nav>
                    {token ? (
                        <Button onClick={logout}>
                            Logout
                        </Button>
                    ) : (
                        <Button onClick={() => dispatch(setShowLoginDialog(true))}>
                            Login
                        </Button>
                    )}
                </Container>
            </Navbar>
        </>
    )
}

export const tabs = {
    home: TAB_HOME,
    watchedStocks: TAB_WATCHED_STOCKS,
    portfolio: TAB_PORTFOLIO,
    spend: TAB_SPEND,
} as const;

export default MainNavBar; 