import React, { useState, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import API from '../lib/API';
import { ImportPersonalTransactionsModalProps } from '../types';

const ImportPersonalTransactionsModal: React.FC<ImportPersonalTransactionsModalProps> = ({ show, handleClose, handleImportComplete }) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [defaultTags, setDefaultTags] = useState<string>('');
    const [importJSON, setImportJSON] = useState<string>('');
    const token = useSelector((state: RootState) => state.status.token);

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (!importJSON) {
            setErrorMessage('Import JSON is required');
            return;
        }

        API.importPersonalTransactions(token, defaultTags, importJSON).then(_ => {
            setErrorMessage('');
            setDefaultTags('');
            setImportJSON('');
            handleImportComplete();
        }).catch(error => {
            setErrorMessage(error ? error.toString() : 'Error importing personal transactions');
        });
    };

    return (
        <Modal show={show} onHide={handleClose} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Import Personal Transactions</Modal.Title>
            </Modal.Header>
            <Modal.Body className='show-grid'>
                <Container>
                    {errorMessage ? <Alert variant='danger'>{errorMessage}</Alert> : null}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Default tags to apply on each transaction for this import</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Default Tags" 
                                value={defaultTags} 
                                onChange={(e) => setDefaultTags(e.target.value)} 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Import JSON Value</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={6} 
                                placeholder="JSON of transactions to import" 
                                value={importJSON} 
                                onChange={(e) => setImportJSON(e.target.value)} 
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-secondary mr-2" onClick={handleClose}>Cancel</button>
                            <Button variant="primary" type="submit">
                                Import
                            </Button>
                        </div>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default ImportPersonalTransactionsModal; 