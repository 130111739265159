import axios from 'axios';
import { 
    LoginResponse,
    APIError,
    Company,
    WatchedStock,
    Portfolio,
    Transaction,
    StockValue
} from '../types/index';

const METHOD_GET = 'GET';
const METHOD_POST = 'POST';
const METHOD_PUT = 'PUT';
const METHOD_PATCH = 'PATCH';
const METHOD_DELETE = 'DELETE';

const BASE_URL = 'https://api.caramel.business'; // 'http://127.0.0.1:8000';
const ERROR_MESSAGE = "We weren't able to reach the server, try again later";

type RequestMethod = typeof METHOD_GET | typeof METHOD_POST | typeof METHOD_PUT | typeof METHOD_PATCH | typeof METHOD_DELETE;

function _request<T>(method: RequestMethod, path: string, token: string = '', data: any = {}): Promise<T> {
    return new Promise((resolve, reject) => {
        const queryParams = method === 'GET' ? new URLSearchParams(data).toString() : '';
        axios({
            headers: token ? {'Authorization': `Token ${token}`} : {},
            method: method,
            url: `${BASE_URL}/${path}${queryParams ? `?${queryParams}` : ''}`,
            data: method === 'GET' ? {} : data,
        }).then(axiosResponse => {
            resolve(axiosResponse.data);
        }).catch(error => {
            console.log(error);
            if (error.response) {
                reject(error.response.data.detail);
            } else if (error.request) {
                reject({
                    code: 500,
                    message: ERROR_MESSAGE,
                    error: error.request
                } as APIError);
            } else {
                reject({
                    code: 500,
                    message: ERROR_MESSAGE,
                    error: error.message
                } as APIError);
            }
        });
    });
}

const login = (username: string, password: string): Promise<LoginResponse> => {
    return _request<LoginResponse>(METHOD_POST, 'api-auth/', '', { username, password });
}

const getCompanies = (): Promise<Company[]> => {
    return _request<Company[]>(METHOD_GET, 'stocks/companies/');
}

const getIPOs = (): Promise<Company[]> => {
    return _request<Company[]>(METHOD_GET, 'stocks/ipos/');
}

const getWatchedStocks = (token: string): Promise<WatchedStock[]> => {
    return _request<WatchedStock[]>(METHOD_GET, 'stocks/watch/', token);
}

const createWatchedStock = (token: string, ticker: string, price: string, emailList: string, comparisonInfo: { comparison: 'less' | 'greater' }): Promise<WatchedStock> => {
    return _request<WatchedStock>(METHOD_POST, 'stocks/watch/', token, { ticker, price, emailList, comparisonInfo });
}

const updateWatchedStock = (token: string, pk: number, ticker: string, price: string, emailList: string, comparisonInfo: { comparison: 'less' | 'greater' }): Promise<WatchedStock> => {
    return _request<WatchedStock>(METHOD_PUT, `stocks/watch/${pk}/`, token, { ticker, price, emailList, comparisonInfo });
}

const deleteWatchedStock = (token: string, pk: number): Promise<void> => {
    return _request<void>(METHOD_DELETE, `stocks/watch/${pk}/`, token);
}

const getStockPortfolios = (token: string): Promise<Portfolio[]> => {
    return _request<Portfolio[]>(METHOD_GET, 'stocks/portfolio/', token);
}

const createStockPortfolio = (token: string, userID: number, name: string, participants: string): Promise<Portfolio> => {
    return _request<Portfolio>(METHOD_POST, 'stocks/portfolio/', token, { userID, name, participants });
}

const updateStockPortfolio = (token: string, pk: number, userID: number, name: string, participants: string): Promise<Portfolio> => {
    return _request<Portfolio>(METHOD_PUT, `stocks/portfolio/${pk}/`, token, { userID, name, participants });
}

const deleteStockPortfolio = (token: string, pk: number): Promise<void> => {
    return _request<void>(METHOD_DELETE, `stocks/portfolio/${pk}/`, token);
}

const getStockTransactions = (token: string): Promise<Transaction[]> => {
    return _request<Transaction[]>(METHOD_GET, 'stocks/transaction/', token);
}

const createStockTransaction = (token: string, userID: number, ticker: string, price: string, quantity: number, date: string, type: 'BUY' | 'SELL', portfolio?: number, note?: string): Promise<Transaction> => {
    return _request<Transaction>(METHOD_POST, 'stocks/transaction/', token, { userID, ticker, price, quantity, date, type, portfolio, note });
}

const updateStockTransaction = (token: string, pk: number, userID: number, ticker: string, price: string, quantity: number, date: string, portfolio?: number, note?: string): Promise<Transaction> => {
    return _request<Transaction>(METHOD_PATCH, `stocks/transaction/${pk}/`, token, { userID, ticker, price, quantity, date, portfolio, note });
}

const deleteStockTransaction = (token: string, pk: number): Promise<void> => {
    return _request<void>(METHOD_DELETE, `stocks/transaction/${pk}/`, token);
}

const getStockCurrentValues = (token: string): Promise<StockValue[]> => {
    return _request<StockValue[]>(METHOD_GET, 'stocks/currentvalues/', token);
}

const getPersonalTransactions = (token: string): Promise<any[]> => {
    return _request<any[]>(METHOD_GET, 'pfinance/transactions/', token);
}

const importPersonalTransactions = (token: string, defaultTags: string, jsonInput: string): Promise<void> => {
    return _request<void>(METHOD_POST, 'pfinance/transactions/import/', token, { defaultTags, jsonInput });
}

const deletePersonalTransaction = (token: string, pk: number): Promise<void> => {
    return _request<void>(METHOD_DELETE, `pfinance/transactions/${pk}/`, token);
}

const getUserTags = (token: string): Promise<any[]> => {
    return _request<any[]>(METHOD_GET, 'pfinance/tags/', token);
}

const deleteUserTag = (token: string, pk: number): Promise<void> => {
    return _request<void>(METHOD_DELETE, `pfinance/tags/${pk}/`, token);
}

const API = {
    login,
    getIPOs,
    getCompanies,
    getWatchedStocks,
    createWatchedStock,
    updateWatchedStock,
    deleteWatchedStock,
    getStockPortfolios,
    createStockPortfolio,
    updateStockPortfolio,
    deleteStockPortfolio,
    getStockTransactions,
    createStockTransaction,
    updateStockTransaction,
    deleteStockTransaction,
    getStockCurrentValues,
    getPersonalTransactions,
    importPersonalTransactions,
    deletePersonalTransaction,
    getUserTags,
    deleteUserTag,
};

export default API; 