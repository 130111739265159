import React, { useState, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import API from '../lib/API';
import { PortfolioFormModalProps } from '../types';

const PortfolioFormModal: React.FC<PortfolioFormModalProps> = ({ show, portfolio, handleClose }) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [name, setName] = useState<string>(portfolio ? portfolio.name : '');
    const token = useSelector((state: RootState) => state.status.token);
    const userID = useSelector((state: RootState) => state.status.userID);

    const submit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (!name) {
            setErrorMessage('Name is required');
            return;
        }

        if (portfolio) {
            API.updateStockPortfolio(token, portfolio.id, userID ?? 0, name, '').then(_ => {
                handleClose();
            }).catch(error => {
                setErrorMessage(error ? error.toString() : 'Error updating stock portfolio');
            });
        } else {
            API.createStockPortfolio(token, userID ?? 0, name, '').then(_ => {
                handleClose();
            }).catch(error => {
                setErrorMessage(error ? error.toString() : 'Error creating stock portfolio');
            });
        }
    };

    const deleteStockPortfolio = (): void => {
        if (!portfolio) {
            return;
        }

        API.deleteStockPortfolio(token, portfolio.id).then(_ => {
            handleClose();
        }).catch(error => {
            setErrorMessage(error ? error.toString() : 'Error deleting stock portfolio');
        });
    }
    
    return (
        <Modal show={show} onHide={handleClose} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{portfolio ? portfolio.name : 'New Stock Portfolio'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='show-grid'>
                <Container>
                    {errorMessage ? <Alert variant='danger'>{errorMessage}</Alert> : null}
                    <Form onSubmit={submit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Portfolio name" 
                                value={name} 
                                onChange={e => setName(e.target.value)} 
                            />
                        </Form.Group>
                        <div className="d-flex gap-2">
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                            {portfolio ? (
                                <Button variant="danger" onClick={deleteStockPortfolio}>Delete</Button>
                            ) : null}
                        </div>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default PortfolioFormModal; 