import React from 'react';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ConfirmationModalProps } from '../types';

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ show, title, message, onConfirm, onCancel }) => {
    return (
        <Modal show={show} onHide={onCancel} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='show-grid'>
                <Container>
                    <p>{message}</p>
                    <div className="d-flex justify-content-end">
                        <Button variant="default" onClick={onCancel}>Cancel</Button>
                        <Button variant="primary" onClick={onConfirm}>Confirm</Button>
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default ConfirmationModal; 