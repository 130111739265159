import React from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { setCurrentCompany } from '../slices/companiesSlice';
import { CompanyDetailsModalProps, Company } from '../types';

const CompanyDetailsModal: React.FC<CompanyDetailsModalProps> = ({ show, company }) => {
    const dispatch = useDispatch();

    if (company === null || company === undefined) {
        return null;
    }

    const keys: (keyof Company)[] = ['ticker', 'name', 'industry', 'sector', 'description', 'website', 'marketCap'];
    const ipoKeys = ['offerDate', 'offerPrice'] as const;
    const additionalDataKeys = Object.keys(company.additionalData ? company.additionalData : {});

    const isIPO = company.ipo ? Object.keys(company.ipo).length > 0 : false;

    const renderDetails = () => {
        return (
            <div>
                {keys.map(key => {
                    const value = company[key];
                    return <p key={key + 'i'}><span><b>{key}:</b></span> <span>{typeof value === 'string' ? value : JSON.stringify(value)}</span></p>
                })}
            </div>
        )
    };

    const renderAdditionalData = () => {
        if (additionalDataKeys.length <= 0) {
            return null;
        }

        return (
            <div>
                <hr/>
                <h3>Data dump</h3>

                {additionalDataKeys.map(key => {
                    const value = company.additionalData?.[key];
                    return <p key={key + 'add'}><span><b>{key}:</b></span> <span>{typeof value === 'string' ? value : JSON.stringify(value)}</span></p>
                })}
            </div>
        )
    }

    const renderIPODetails = () => {
        if (!isIPO || !company.ipo) {
            return null;
        }

        const ipo = company.ipo;
        return (
            <div>
                <h3>IPO Data</h3>
                {ipoKeys.map(key => {
                    const value = ipo[key];
                    return <p key={key + 'ipo'}><span><b>{key}</b></span> <br/> <span>{value}</span></p>
                })}
            </div>
        )
    };

    const handleClose = () => {
        dispatch(setCurrentCompany(null));
    }

    return (
        <Modal show={show} onHide={handleClose} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{company.ticker}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='show-grid'>
                <Container>
                    <Row>
                        <Col md={9}>
                            {renderDetails()}
                            {renderAdditionalData()}
                        </Col>
                        <Col md={3}>
                            {renderIPODetails()}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default CompanyDetailsModal; 