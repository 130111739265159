import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TransactionsState, Transaction, PersonalTransaction } from "../types";

const initialState: TransactionsState = {
    transactions: [],
    personalTransactions: [],
};

export const transactionsReducer = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        setTransactions: (state, action: PayloadAction<Transaction[]>) => {
            state.transactions = action.payload;
        },
        setPersonalTransactions: (state, action: PayloadAction<PersonalTransaction[]>) => {
            state.personalTransactions = action.payload;
        },
    },
});

export const { setTransactions, setPersonalTransactions } = transactionsReducer.actions;

export default transactionsReducer.reducer; 