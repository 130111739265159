import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TagsState, Tag } from "../types";

const initialState: TagsState = {
    tags: [],
};

export const tagsReducer = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        setTags: (state, action: PayloadAction<Tag[]>) => {
            state.tags = action.payload;
        }
    },
});

export const { setTags } = tagsReducer.actions;

export default tagsReducer.reducer; 